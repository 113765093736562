export default {
  home: {
    language: "中文",
    qianbao: "连接钱包",
    welcom: "欢迎来到",
    introduce:
      "新一代去中心化的NFT艺术市场，为世界各地的数字艺术家、创作者和收藏家不断迭代。",
    zongshu: "总金额：",
    zhuzao: "已铸造：",
    Address: "地址：",
    Spend: "花费：",
    CastNFT: "铸造NFT",
    Account: "账户",
    Amount: "数量：",
    invitees: "受邀人数：",
    Award: "奖励",
    Invitelink: "邀请链接",
    Copylink: "复制链接",
    partners: "合作伙伴",
    foot: "是艺术NFT资产的先行者，拥有强大的高级创意团队、技术团队和营销团队。相信在Web3.0的世界，在元宇宙的世界里，NFT的价值是最大化的！",
    contact:"联系我们",
    InputCastAmount: "输入铸造数量：",
    Confirm: "确定",
    ConnectTheWallet:"请先连接钱包",
    PleaseEnter:"请输入铸造数量",
    MaximumLimit:"铸造数量已达最大限制",
    Success: "铸造成功!",
    Surplus: "剩余铸造:",
    Personal:"单人铸造限制：",
    infinite: "无限制",
    Tip: "提示：您还可以铸造数量为 " ,
    Invite: "邀请规则",
    YouCan:"您可获得",
    Level:"级用户购买金额 ",
    Rebate:" 佣金"

  },
};
