import { createI18n } from "vue-i18n";
// 从语言包文件中导入语言包对象
import VueI18n from 'vue-i18n'
import zh from "./zh";
import en from "./en";
const messages = {
  zh,
  en,
};
// import { useI18n } from "vue-i18n";
// const { locale } = useI18n();
const i18n = createI18n({
  // 
  globalInjection: true,
  // 设置后备语言
  fallbackLocale: "en",
  legacy: false,
  messages,
  // 默认语言
  locale: "en",
});

// console.log(i18n.messages);
// console.log(i18n.locale);
// console.log(i18n);
export default i18n;
