export default {
  home: {
    language: "English",
    qianbao: "Connect Wallet",
    welcom: "Welcome to",
    introduce:
      "The new generation of decentralized NFT art marketplaces, iterating continuously for digital artists, creators and collectors around the world.",
    zongshu: "Total Amount:",
    zhuzao: "Cast:",
    Address: "Address:",
    Spend: "Spend:",
    CastNFT: "Cast NFT",
    Account: "Account",

    Amount: "Amount:",
    invitees: "Number of invitees:",
    Award: "Award",
    Invitelink: "Invite link",
    Copylink: "Copy link",
    partners: "Our partners",
    foot: " the forerunner of artistic NFT assets, Has a strong senior creative team, technical team and marketing team. believes that the value of NFT is maximized in the world of Web3.0, in the world of the metaverse!",
    contact:"Contact us",
    InputCastAmount: "Input cast amount:",
    Confirm: "Confirm",
    ConnectTheWallet:"Please connect the wallet first",
    PleaseEnter:"Please enter the casting quantity",
    MaximumLimit:"The casting quantity has reached the maximum limit",
    Success: "Success",
    Surplus: "Surplus:",
    Personal:"Personal restrictions：",
    infinite: "infinite",
    Tip: "Tip: You can also cast a quantity of ",
    Invite: "Invite Rules",
    YouCan:"You Can Get ",
    Level:" Level User Purchase Amount ",
    Rebate:" Rebate"
  },
};
