import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "./language";
import VueI18n from "vue-i18n";
const app = createApp(App);
app.use(VueI18n);
app.use(store)
app.use(router)
app.use(i18n)
app.use(ElementPlus)
app.mount("#app");
 