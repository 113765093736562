
export { default as ERC20ABI } from './ABI/ERC20ABI.json'
export { default as NFTABI } from './ABI/NFTABI.json'



// export const FACTORYADDRESS = '0xBCe78093a4058EBa3acfC03B7aC8F45A52cFf3F9'
// export const STARKINGADDRESS = '0xC6A42Fd0f0828541C4Ce83A64ab53e90FD1534A6'



const bscExplorer = { name: 'BscScan', url: 'https://bscscan.com' }

const bsc = {
  chainId: 56,
  name: 'BNB Smart Chain',
  network: 'bsc',
  rpcUrls: {
    public: 'https://bsc-dataseed4.defibit.io',
    default: 'https://bsc-dataseed2.ninicoin.io',
  },
  blockExplorers: {
    default: bscExplorer,
    etherscan: bscExplorer,
  },
  nativeCurrency: {
    name: 'Binance Chain Native Token',
    symbol: 'BNB',
    decimals: 18,
  },
  multicall: {
    address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    blockCreated: 15921452,
  },
}
export const bscTest = {
  chainId: 97,
  name: 'BNB Smart Chain Testnet',
  network: 'bsc-testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Binance Chain Native Token',
    symbol: 'tBNB',
  },
  rpcUrls: {
    public: 'https://data-seed-prebsc-1-s3.bnbchain.org:8545',
    default: 'https://data-seed-prebsc-1-s3.bnbchain.org:8545',
  },
  blockExplorers: {
    default: { name: 'BscScan', url: 'https://testnet.bscscan.com' },
  },
  multicall: {
    address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    blockCreated: 17422483,
  },
  testnet: true,
}
export const CORE = {
  chainId: 1116,
  name: 'CORE',
  network: 'CORE',
  nativeCurrency: { name: 'CORE', symbol: 'CORE', decimals: 18 },
  rpcUrls: {
    default: 'https://rpc.coredao.org/',
  },
  blockExplorers: {
    default: {
      name: 'CORE',
      url: 'https://scan.coredao.org',
    },
  },
  testnet: true,
}
export const ARB = {
  id: 42161,
  name: 'Arbitrum',
  network: 'Arbitrum',
  rpcUrls: {
    default: 'https://arb1.arbitrum.io/rpc',
  },
  nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
  blockExplorers: {
    default: {
      name: 'ARB',
      url: 'https://arbiscan.io',
    },
  },
}
export const mainnet = {
  id: 1,
  name: 'ETH',
  network: 'ETH',
  rpcUrls: {
    default: 'https://virginia.rpc.blxrbdn.com',
  },
  nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
  blockExplorers: {
    default: {
      name: 'ETH',
      url: 'https://etherscan.io/',
    },
  },
}
export const MBK = {
  id: 12133,
  name: 'MBK',
  network: 'MBK',
  rpcUrls: {
    default: 'https://rpcm.mbkscan.com/',
  },
  nativeCurrency: { name: 'MBK', symbol: 'MBK', decimals: 18 },
  blockExplorers: {
    default: {
      name: 'MBK',
      url: ' https://www.mbkscan.com/',
    },
  },
}
export const BASE = {
  id: 8453,
  name: 'BASE',
  network: 'BASE',
  rpcUrls: {
    default: 'https://developer-access-mainnet.base.org',
  },
  nativeCurrency: { name: 'BASE', symbol: 'BASE', decimals: 18 },
  blockExplorers: {
    default: {
      name: 'BASE',
      url: 'https://basescan.org/',
    },
  },
}
export const opBNB = {
  id: 204,
  name: 'opBNB',
  network: 'opBNB',
  rpcUrls: {
    default: 'https://opbnb-mainnet-rpc.bnbchain.org/',
  },
  nativeCurrency: { name: 'opBNB', symbol: 'opBNB', decimals: 18 },
  blockExplorers: {
    default: {
      name: 'opBNB',
      url: 'https://mainnet.opbnbscan.com/',
    },
  },
}
export const HECO = {
  id: 128,
  name: 'HECO',
  network: 'HECO',
  rpcUrls: {
    default: 'https://http-mainnet-node.huobichain.com',
  },
  nativeCurrency: { name: 'HECO', symbol: 'HECO', decimals: 18 },
  blockExplorers: {
    default: {
      name: 'HECO',
      url: 'https://www.hecoinfo.com/',
    },
  },
}

export const NetWorkConfig = {
  '1': mainnet,
  '56': bsc,
  '97': bscTest,
  "42161": ARB,
  "1116": CORE,
  '12133': MBK,
  '8453': BASE,
  '204': opBNB,
  '128': HECO
}