import { BigNumber, ethers } from "ethers";


export const getEther = (val, decimals) => {
    let amount = ethers.utils.formatUnits(val, decimals);
    amount = balanceHandler(amount);
    return amount;
  }
  
export const balanceHandler = (value) => {
    const index = value.indexOf(".");
    const balance = value.slice(0, index + 5);
    return balance;
  };
